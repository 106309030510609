<template>
  <div>
    <van-nav-bar
        title="商户详情"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>

    <van-field
        v-model="data.factoryName"
        label="厂名"
        readonly
        name="pattern"
        placeholder="厂名"
        right-icon="edit"
        @click-right-icon="editUserInfo('厂名', data.factoryName)"
    />
    <van-field name="uploader" label="头像">
      <template #input>
        <van-uploader v-model="fileList" :max-count="1"/>
      </template>
    </van-field>
<!--    <van-field-->
<!--        v-model="accountUser.phone"-->
<!--        label="手机号码"-->
<!--        name="validator"-->
<!--        placeholder="手机号码"-->
<!--        readonly-->
<!--        right-icon="edit"-->
<!--        @click-right-icon="editUserInfo('手机号码', accountUser.phone)"-->
<!--    />-->

    <van-field
        v-model="data.address"
        readonly
        label="地址"
        placeholder="请选择所在地区"
        right-icon="edit"
        @click-right-icon="editUserAddress"
    />
    <van-field
        v-model="data.coordinate"
        readonly
        label="坐标"
        placeholder="请选择所在地区"
        right-icon="edit"
        @click-right-icon="editUserCoordinate"
    />
    <van-cell title="地图地址" :label="mapInfo.address" icon="award-o" />
    <van-cell title="坐标" :label="coordinate" icon="location-o" >
      <template #right-icon>
        <van-button type="primary" size="small" @click="onCoordinateSubmit">保存坐标</van-button>
      </template>
    </van-cell>

    <van-divider
      :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      联系人
    </van-divider>

    <div class="van-contact-list" style="padding-bottom: 0px;">
      <div role="radiogroup" class="van-radio-group van-contact-list__group">
        <div role="button" tabindex="0" class="van-cell van-cell--clickable van-cell--center van-contact-list__item" v-for="(item, index) in data.linkmanList" :key="index" :name="index">
          <div class="van-cell__value van-cell__value--alone van-contact-list__item-value">{{ item.name }}，{{ item.phone }}
            <span class="van-tag van-tag--round van-tag--danger van-contact-list__item-tag" v-if="item.isDefault">默认</span>
          </div>
          <i class="van-icon van-icon-edit van-contact-list__edit" @click="onEditLinkman(item)"><!----></i>
        </div>
        <span style="display: flex; align-items: center; justify-content: center; color: rgb(25, 137, 250); text-decoration:underline; margin-top: 6px;" @click="onAddLinkman">新增联系人</span>
      </div>
<!--      <div class="van-contact-list__bottom">-->
<!--        <button-->
<!--          class="van-button van-button&#45;&#45;danger van-button&#45;&#45;normal van-button&#45;&#45;block van-button&#45;&#45;round van-contact-list__add">-->
<!--          <div class="van-button__content"><span class="van-button__text">新建联系人</span></div>-->
<!--        </button>-->
<!--      </div>-->
    </div>

    <van-popup v-model="selectAddressPopupShow" round position="bottom">
      <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          :field-names="fieldNames"
          @close="selectAddressPopupShow = false"
          @finish="onFinish"
          @change="onAreaChange"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="onAddressSubmit">提交</van-button>
      </div>
    </van-popup>
    <!--    <van-field-->
    <!--        v-model="accountUser.detailedAddress"-->
    <!--        label="详细地址"-->
    <!--        name="pattern"-->
    <!--        placeholder="请输入详细地址"-->
    <!--        readonly-->
    <!--        right-icon="edit  "-->
    <!--        @click-right-icon="editUserInfo('详细地址', accountUser.detailedAddress)"-->
    <!--    />-->

    <van-popup v-model="userInfoEditPopupShow" :style="{ width: '90%' }">
      <van-field v-model="editItem.value"
                 :label="editItem.key"
                 placeholder="请输入修改值"
      />
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit" @click="onSubmit">修改</van-button>
      </div>
    </van-popup>

    <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      订货金额
    </van-divider>

    <van-row style="background-color: #fff;">
      <van-col span="4" offset="20" style="text-align: right">
        <van-button hairline size="small" color="#7232dd" plain style="margin-top: 3px; margin-right: 3px;" @click="toPurchase">详情
        </van-button>
      </van-col>
    </van-row>
    <van-row class="user-links">
      <van-col span="6">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfDay }}</span>
        第一季度
      </van-col>
      <van-col span="6">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfWeek }}</span>
        第二季度
      </van-col>
      <van-col span="6">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfMonth }}</span>
        第三季度
      </van-col>
      <van-col span="6">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfYear }}</span>
        第四季度
      </van-col>
    </van-row>
    <van-row class="user-links">
      <van-col span="12">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amount }}</span>
        今年
      </van-col>
      <van-col span="12">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amount }}</span>
        总销量
      </van-col>
    </van-row>
    <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      更多操作
    </van-divider>

    <div class="van-share-sheet__options">
      <div role="button" tabindex="0" class="van-share-sheet__option" @click="onPay">
        <img src="https://img01.yzcdn.cn/vant/share-sheet-link.png" class="van-share-sheet__icon">
        <span class="van-share-sheet__name">付货款</span>
      </div>
      <div role="button" tabindex="0" class="van-share-sheet__option" @click="toPrice">
        <img src="https://img01.yzcdn.cn/vant/share-sheet-qrcode.png" class="van-share-sheet__icon">
        <span class="van-share-sheet__name">已订价格表</span>
      </div>

<!--      <div role="button" tabindex="0" class="van-share-sheet__option">-->
<!--        <img src="https://img01.yzcdn.cn/vant/share-sheet-link.png" class="van-share-sheet__icon">-->
<!--        <span class="van-share-sheet__name">复制链接</span>-->
<!--        <span class="van-share-sheet__option-description">描述信息</span>-->
<!--      </div>-->
<!--      <div role="button" tabindex="0" class="van-share-sheet__option"><img-->
<!--          src="https://img01.yzcdn.cn/vant/share-sheet-poster.png" class="van-share-sheet__icon"><span-->
<!--          class="van-share-sheet__name">分享海报</span></div>-->
<!--      <div role="button" tabindex="0" class="van-share-sheet__option"><img-->
<!--          src="https://img01.yzcdn.cn/vant/share-sheet-wechat.png" class="van-share-sheet__icon"><span-->
<!--          class="van-share-sheet__name">二维码</span></div>-->
    </div>

    <div style="margin: 16px;">
      <van-button block type="danger" native-type="submit" @click="onDelete">删除厂家</van-button>
    </div>
    <GeneralPayPopup  ref="generalPayPopup"
                      :payData="payData"
                      @payData="onEmitPayData">
    </GeneralPayPopup>

    <van-popup v-model="contactEditPopupShow" :style="{ width: '90%' }" @close="onCloseContactEditPopupShow">
      <div class="van-contact-edit">
        <div class="van-contact-edit__fields">
          <!-- 输入任意文本 -->
          <van-field v-model="editingContact.name" label="姓名" placeholder="请填写姓名" />
          <!-- 输入手机号，调起手机号键盘 -->
          <van-field v-model="editingContact.phone" type="tel" label="电话" placeholder="请填写电话" />
        </div>
        <van-cell center title="设为默认联系人">
          <template #right-icon>
            <van-switch v-model="editingContact.isDefault" size="24" />
          </template>
        </van-cell>
        <div class="van-contact-edit__buttons">
          <button class="van-button van-button--danger van-button--normal van-button--block van-button--round" @click="onLinkmanSave">
            <div class="van-button__content"><span class="van-button__text">保存</span></div>
          </button>
          <button class="van-button van-button--default van-button--normal van-button--block van-button--round" @click="onLinkmanDelete">
            <div class="van-button__content"><span class="van-button__text">删除</span></div>
          </button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {Cascader, ContactEdit, ContactList, Divider, Field, NavBar, Notify, ShareSheet, Switch, Uploader} from 'vant';
import * as factoryAdminService from "@/api/admin/factory";
import $store from "@/store";
import * as getDataService from "@/api/serverData/getServerData";
import {mapState} from "vuex";
import GeneralPayPopup from "@/components/generalPayPopup.vue";
import * as purchaseService from "@/api/admin/purchase";


export default {
  components: {
    GeneralPayPopup,
    [Notify.name]: Notify,
    [Divider.name]: Divider,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [NavBar.name]: NavBar,
    [ShareSheet.name]: ShareSheet,
    [Cascader.name]: Cascader,
    [ContactList.name]: ContactList,
    [ContactEdit.name]: ContactEdit,
    [Switch.name]: Switch,
  },
  data() {
    return {
      sms: '',
      fileList: [
        {url: 'https://img01.yzcdn.cn/vant/leaf.jpg'},
      ],
      editItem: {},
      analysis: {},
      userInfoEditPopupShow: false,
      data: {},
      pattern: /\d{6}/,
      uploader: [{url: 'https://img01.yzcdn.cn/vant/leaf.jpg'}],
      selectAddressPopupShow: false,
      cascaderValue: '',
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      fieldNames: {
        text: 'n',
        value: 'v',
        children: 'c',
      },
      payData: {},
      isEditContact: false,
      editingContact: {
        name: '',
        tel: '',
        isDefault: false,
        id: null
      },
      contactEditPopupShow: false
    };
  },
  computed: {
    ...mapState({
      mapInfo: state => state.map.mapInfo,
    }),
    coordinate: function () {
      if ($store.state.map.mapInfo.lat !== undefined) {
        return $store.state.map.mapInfo.lat+ ',' + $store.state.map.mapInfo.lng
      }
      return null
    },
  },
  watch : {
    coordinate: function(val) {
      this.accountUser.coordinate = val;
    },
  },
  created() {
    this.getData()
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
  },
  methods: {
    getData() {
      if (this.$route.params && this.$route.params.uuid) {
        factoryAdminService.getFactory(this.$route.params.uuid).then(res => {
          if (res.status === 200) {
            this.data = res.data
            this.fileList = [{url: res.data.avatar}]
          }
        })

      //   userAdminService.getAnalysis(this.$route.params.userId).then(res => {
      //     if (res.status === 200) {
      //       this.analysis = res.data
      //       if (this.analysis.amountOfDay === null) {
      //         this.analysis.amountOfDay = 0
      //       }
      //       if (this.analysis.amountOfWeek === null) {
      //         this.analysis.amountOfWeek = 0
      //       }
      //       if (this.analysis.amountOfMonth === null) {
      //         this.analysis.amountOfMonth = 0
      //       }
      //       if (this.analysis.amountOfYear === null) {
      //         this.analysis.amountOfYear = 0
      //       }
      //       if (this.analysis.amount === null) {
      //         this.analysis.amount = 0
      //       }
      //       console.log(res.data)
      //     }
      //   })
      }
    },
    editUserCoordinate() {
      this.$router.push({path: '/map'})
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    toPrice() {
      this.$router.push({path: '/price/purchaseList', query: {factoryUuid: this.$route.params.uuid}})
    },
    toPurchase() {
      this.$router.push({path: '/purchase/list', query: {factoryUuid: this.$route.params.uuid}})
    },
    editUserAddress() {
      this.selectAddressPopupShow = true
    },
    editUserInfo(key, username) {
      this.userInfoEditPopupShow = true
      this.editItem.key = key
      this.editItem.value = username
    },
    onSubmit() {
      if (this.editItem.value === undefined) {
        this.$toast("修改值不能为空")
        return
      }
      let editData = {}
      editData.id = this.data.id
      if (this.editItem.key === '厂名') {
        editData.factoryName = this.editItem.value
      } else if (this.editItem.key === '详细地址') {
        editData.address = this.editItem.value
      }
      factoryAdminService.editFactory(editData).then(res => {
        if (res.status === 200) {
          this.userInfoEditPopupShow = false
          this.editItem = {}
          this.$toast("修改成功")
          this.getData()
        }
      })
    },
    onDelete() {
      this.$toast('该功能暂未开放')
    },
    onAddressSubmit() {
      console.log(this.cascaderValue)
      if (this.data.address === undefined) {
        this.$toast("修改值不能为空")
        return
      }
      let editData = {}
      editData.id = this.data.id
      editData.address = this.data.address
      factoryAdminService.editFactory(editData).then(res => {
        if (res.status === 200) {
          this.editItem = {}
          this.$toast('修改成功')
          this.getData()
          this.selectAddressPopupShow = false
          this.userInfoEditPopupShow = false
        }
      })
    },
    onCoordinateSubmit() {
      if (this.coordinate === undefined || this.coordinate === null || this.coordinate === '') {
        this.$toast("修改坐标不能为空")
        return
      }
      let editData = {}
      editData.id = this.data.id
      editData.coordinate = this.coordinate
      factoryAdminService.editFactory(editData).then(res => {
        if (res.status === 200) {
          this.editItem = {}
          this.$toast('修改成功')
          $store.commit('SET_MAP_INFO', {})
          this.getData()
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({selectedOptions}) {
      this.selectAddressPopupShow = false;
      this.data.address = selectedOptions.map((option) => option.n).join(' ');
    },
    onAreaChange({selectedOptions}) {
      this.data.address = selectedOptions.map((option) => option.n).join(' ');
    },
    onAddLinkman() {
      this.contactEditPopupShow = true
    },
    onEditLinkman(item) {
      this.isEditContact = true
      this.editingContact.name = item.name
      this.editingContact.phone = item.phone
      this.editingContact.id = item.id
      this.editingContact.uuid = item.uuid
      if (item.isDefault === 1) {
        this.editingContact.isDefault = true
      }
      this.contactEditPopupShow = true
    },
    onLinkmanSave() {
      if (this.isEditContact) {
        let editData = {
          factoryUuid: this.$route.params.uuid,
          name: this.editingContact.name,
          phone: this.editingContact.phone,
          id: this.editingContact.id,
          uuid: this.editingContact.uuid,
        }
        if (this.editingContact.isDefault) {
          editData.isDefault = 1
        }
        factoryAdminService.editLinkman(editData).then(res => {
          if (res.status === 200) {
            this.$toast('提交成功')
            this.contactEditPopupShow = false
            this.isEditContact = false
            this.editingContact = {}
            this.getData()
          }
        })
        return
      }

      let addData = {
        factoryUuid: this.$route.params.uuid,
        name: this.editingContact.name,
        phone: this.editingContact.phone
      }
      if (this.editingContact.isDefault) {
        addData.isDefault = 1
      }
      factoryAdminService.addLinkman(addData).then(res => {
        if (res.status === 200) {
          this.$toast('提交成功')
          this.contactEditPopupShow = false
          this.editingContact = {}
          this.getData()
        }
      })
    },
    onLinkmanDelete() {
      if (!this.editingContact.id) {
        this.$toast('无效操作')
        return
      }
      factoryAdminService.deleteLinkman(this.editingContact.id).then(res => {
        if (res.status === 200) {
          this.$toast('删除成功')
          this.contactEditPopupShow = false
          this.editingContact = {}
          this.getData()
        }
      })
    },
    onCloseContactEditPopupShow() {
      this.editingContact = {}
    },
    onPay() {
      this.payData = {
        factoryUuid: this.data.uuid,
        text: '向' + this.data.factoryName + '支付货款',
        payType: '微信',
        payDate: new Date(),
        payDateStr: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
      }
      this.$refs.generalPayPopup.isPaymentShow = true
    },
    onEmitPayData(data) {
      console.log('onEmitPayData', data)
      purchaseService.factoryPay(data).then(res => {
        if (res.status === 200) {
          this.$toast('提交成功')
          this.$refs.generalPayPopup.isPaymentShow = false
        }
      })
    },
  },
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
