import { render, staticRenderFns } from "./particulars.vue?vue&type=template&id=6a79e1d0"
import script from "./particulars.vue?vue&type=script&lang=js"
export * from "./particulars.vue?vue&type=script&lang=js"
import style0 from "./particulars.vue?vue&type=style&index=0&id=6a79e1d0&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports