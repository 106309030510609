import request from '@/utils/admin/request'

export function getFactory(uuid) {
    return request({
        url: 'charge-account/purchase/factory/' + uuid,
        method: 'get',
    })
}

export function editFactory(data) {
    return request({
        url: 'charge-account/purchase/factory',
        method: 'put',
        data: data
    })
}

export function addLinkman(data) {
    return request({
        url: 'charge-account/purchase/factory/add-linkman',
        method: 'post',
        data: data
    })
}

export function editLinkman(data) {
    return request({
        url: 'charge-account/purchase/factory/linkman',
        method: 'put',
        data: data
    })
}

export function deleteLinkman(id) {
    return request({
        url: 'charge-account/purchase/factory/linkman/' + id,
        method: 'delete',
    })
}
